import { GetStaticPropsContext } from 'next';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';

export default function Page404() {
  const t = useTranslations();
  return (
    <div className="flex justify-center flex-col align-items-center h-screen ">
      <div className="max-w-md p-4 text-center">
        <Image src={'/assets/error.svg'} width={200} height={200} alt={'404'} />
        <h1 className="headingMHeavy lg:headingL text-SnapDarkBlue">
          {t('shared.error.notFound')}
        </h1>
        <p>{t('shared.error.notFoundSummary')}</p>
      </div>
    </div>
  );
}

export async function getStaticProps({
  locale
}: GetStaticPropsContext<any, any>) {
  return {
    props: {
      noLayout: true,
      messages: {
        shared: { ...require(`../i18n/shared/${locale}.json`) }
      }
    }
  };
}
